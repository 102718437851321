import * as React from "react"
import { Button, Card, Progress } from "antd"
import ThemeCardStyle from "../less/themecards.module.less"
import PropTypes from "prop-types"

const ThemeCard = ({ title, amount, projects, info }) => (
  <Card
    hoverable>
    <div className={ThemeCardStyle.box}>
      <div className={ThemeCardStyle.theme}>
        <div>
          <p>€ {amount}</p>
        </div>
        <div>
          <Progress percent={30}/>
        </div>
      </div>
      <h5 className={ThemeCardStyle.title}>{title}</h5>
    </div>

    {projects || info ? <div className={ThemeCardStyle.themeButton}>
      <Button>{projects}</Button>
      <Button>{info}</Button>
    </div> : ""}
  </Card>
)

ThemeCard.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.number,
  projects: PropTypes.string,
  info: PropTypes.string,
}

ThemeCard.defaultProps = {
  title: ``,
  amount: ``,
  projects: ``,
  info: ``,
}

export default ThemeCard
