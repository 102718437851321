import * as React from "react"
import {Button, Card} from "antd"
import {Link} from "gatsby-plugin-intl"
import NewsCardStyle from "../less/news-card.module.less"
import PropTypes from "prop-types"
import useIntl from "../helpers/useIntl"

const {Meta} = Card

const NewsCard = ({title, description, link, coverImageLink, coverImageAlt}) => {
  const intl = useIntl()
  return (
    <Link to={`/news/${link}`}>
      <Card hoverable
            className={NewsCardStyle.news}
            cover={<img src={coverImageLink} alt={coverImageAlt}/>}>
        <Meta title={title}
              description={description}/>
        <div className={NewsCardStyle.cardFooter}>

          <Button
            type={"link"}>{intl.formatMessage({id: "general_readmore"})}</Button>
        </div>
      </Card>
    </Link>
  )
}

NewsCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  coverImageLink: PropTypes.string,
  coverImageAlt: PropTypes.string,
}

NewsCard.defaultProps = {
  title: ``,
  description: ``,
  link: ``,
}

export default NewsCard
